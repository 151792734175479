<template>
  <section class="products">
    <v-img
      v-if="banner"
      :src="banner"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center id="news_top" style="margin-bottom:10px">
      <template>
        <div>
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container
        fluid
        grid-list-sm
        mt-4
        mb-12
      >
        <v-layout wrap v-if="detail" class="text-left">
          <!--<v-carousel
            cycle
            hide-delimiter-background
            show-arrows-on-hover
            delimiter-icon="mdi-minus"
            max-height="420"
            height="auto"
          >
            <v-carousel-item
              v-for="(img, ii) in detail.imgs"
              max-height="420"
              :key="ii"
              :src="img.img"
            ></v-carousel-item>
          </v-carousel>-->
          <v-flex xs12 sm12 md4 lg4>
            <h3 style="text-align: left;margin-bottom: 30px;">{{detail.title}}</h3>
            <v-img
              :src="detail.img"
              aspect-ratio="1"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md8 lg8 pl-10>
            <ul v-if="detail.attr" style="list-style: none; padding:0px;">
              <li v-for="(attr, i) in detail.attr" :key="i" style="margin: 20px 0px;">
                <span style="color:#666; font-size:16px;margin-right:60px;">{{attr.attr_name}}:</span>
                <span style="color:#333; font-size:16px;">{{attr.attr_value}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex mt-4>
            <div  v-html="detail.content" class="ctk-news-content">
              {{detail.content}}
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 2,
    category: null,
    detail: null,
    banner: null,
    breadcrumbs: []
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.getCategory()
  },
  watch: {
    category: {
      handler: function (val, oldVal) {
        this.parseBreadcrumbs(val)
      },
      deep: true
    },
    detail: {
      handler: function (val, oldVal) {
        this.breadcrumbs.push({ text: val.title, disabled: true, href: '' })
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCategory () {
      this.https.get('product_category', { cid: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.category = response.data
          if (this.category.cat.banner) {
            this.banner = this.category.cat.banner
          } else {
            this.banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
          }
          this.getProduct()
        }
      })
    },
    getProduct () {
      this.https.get('product', { id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.detail = response.data
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs = [
        { text: '首页', disabled: false, href: '/' },
        { text: '产品中心', disabled: false, href: '/products' }
      ]
      this.breadcrumbs.push({ text: category.cat.name, disabled: false, href: '/products/' + category.cat.id })
    }
  }
}
</script>
